@import "../../global/variables";
@import "../../global/mixins";

.wrapper {
  font-family: 'Roboto', sans-serif;
  position: sticky;
  top: 20px;
  display: flex;
  flex-direction: column;
  width: 375px;
  background: white;
  border: 8px solid $black;
  border-radius: 40px;

  .outerBorder {
    border: 6px solid #5C5C6B;
    width: 359px;
    border-radius: 30px;
    padding: 8px 8px 20px 20px;
  }

  .scrollContainer {
    padding-right: 4px;
    height: 748px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: $blackButton;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $black;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    .headerLeft {
      display: flex;
      align-items: center;

      .camera {
        margin-right: 14px;
      }
    }

    .headerRight {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .text {
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .iconsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;

    .rightSide {
      display: flex;
      align-items: center;

      .searchIcon {
        margin-right: 12px;
      }
    }
  }

  .iconContainer {
    display: flex;
    margin-bottom: 31px;

    .icon {
      margin-right: 20px;

      img {
        border-radius: 12px;
      }

    }

    .iconTitle {
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 22px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0.66px;
    }

    .iconSubtitle {
      color: $green;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.28px;
      padding-right: 10px;
    }
  }

  .infoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;


    overflow-x: scroll;
    &::-webkit-scrollbar {
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $lightGrey;
    }


    .reviews {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 34px;
      margin-left: auto;

      &_top {
        display: flex;
      }

      &_ratingText {
        color: $greyPrimary;
        font-size: 12px;
      }
    }

    .downloads {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 34px;
      margin-right: 34px;

      .count {
        color: rgba(0, 0, 0, 0.8);
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }


    }

    .age {
      display: flex;
      flex-direction: column;
      margin-left: 34px;
      margin-right: 34px;

      img {
        margin-bottom: 4px;
        margin-left: auto;
        margin-right: auto;
      }

      p {
        color: rgba(0, 0, 0, 0.6);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

  .installBtn {
    width: 100%;
    padding: 0;
    height: 32px;
    margin-top: 21px;

    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.32px;
    border-radius: 4px;
    background: $green;
    border: none;
    margin-bottom: 27px;
  }

  .galleryContainer {
    display: flex;
    margin-bottom: 27px;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    gap: 12.5px;

    .galleryItem {
      height: 140px;
      width: 80px;
      border-radius: 6px;
      object-fit: cover;
      background: $lightGrey;
    }
  }


  .aboutAppContainer {
    display: flex;
    flex-direction: column;

    .titleRow {
      display: flex;
      justify-content: space-between;

      h4 {
        color: rgba(0, 0, 0, 0.8);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0.48px;
      }
    }

    .description {
      margin-bottom: 20px;

      p {
        color: rgba(0, 0, 0, 0.8);
        font-size: 12px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.36px;
      }
    }

    .tags {
      display: flex;
      margin-bottom: 35px;

      .tag {
        border-radius: 40px;
        background: #fff;
        border: 2px solid $greyPrimary;
        padding: 6px 12px;
      }
    }

    .ratingBlock {
      display: flex;
      flex-direction: column;

      h3 {
        color: rgba(0, 0, 0, 0.8);
        font-size: 16px;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0.48px;
      }

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .leftSide{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .bigNumb {
          color: rgba(0, 0, 0, 0.8);
          font-size: 48px;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: 1.44px;
        }

        .reviewsCount {
          color: $greyPrimary;
          font-size: 11px;
          text-align: center;
          padding-top: 5px;
        }


        .rightSide {
          width: 203px;

          .sliderRow {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            p {
              cursor: no-drop;
              margin-right: 9px;
              color: rgba(0, 0, 0, 0.8);
              font-size: 10px;
              font-weight: 400;
              line-height: 100%;
              letter-spacing: 0.3px;
            }

            .slider {
              width: 100%;
              cursor: no-drop;
            }
          }
        }
      }
    }

    .titleContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 21px;
    }
  }

  .readMore {
    color: $green;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    padding-bottom: 16px;
  }

  .commentContainer {
    padding-top: 24px;
    display: grid;
    grid-template-columns: 48px 1fr;

    .commentAvatar {
      display: block;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: $yellow;
    }
  }

  .commentHeader {
    padding-left: 12px;
    display: flex;
    gap: 12px;

    .commentName {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #000000;
    }

    .commentDate {
      font-size: 12px;
      color: #666666;
      letter-spacing: 1px;
    }

    .likesCount {
      height: 0;
      font-size: 10px;
      color: #666666;
    }

    .likesContainer {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .commentText {
    font-size: 12px;
    line-height: 17px;
    font-weight: 400;
    padding: 14px 0 0 12px;
    max-width: 258px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .cone {
    padding-left: 15px;
    margin-bottom: -4px;
  }

  .reply {
    min-width: 310px;
    width: 100%;
    background-color: #E5E5E5;
    padding: 12px 12px;

    .replyTop {
      display: flex;
      justify-content: space-between;
    }

    .replyAuthor {
      padding-bottom: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.8);
    }



    .replayText, .replyDate {
      color: #666666;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      letter-spacing: 0.03em;
      word-wrap: break-word;
      overflow-wrap: break-word;

    }
  }

  .whatsNew {
    border-top: 2px solid $lightGrey;
    border-bottom: 2px solid $lightGrey;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    &_text {
      font-size: 16px;
      font-weight: 400;
      color: $blackButton;
    }

    &_bonus {
      font-size: 14px;
      color: $blackButton;
      padding-top: 20px;
    }
  }

  .additionalInfo {
    border-bottom: 2px solid $lightGrey;
    padding-bottom: 20px;
    &_title {
      color: $greyRegular;
      font-size: 16px;
      padding-top: 20px;
    }
  }

  .additionalContainer {
    display: flex;
    justify-content: space-between;


    .characterization{
      margin-top: 20px;

      &_topText {
        font-size: 15px;
        padding-bottom: 10px;
        line-height: 25px;
        color: black;
      }

      &_bottomText {
        color: $greyRegular;
        font-size: 14px;
      }

    }
  }
}
