@import "../../global/variables";

.auth {
  background-color: black;
  background-image: url("../../img/blackLightningBg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right calc(50vw - 464px - 654px / 2);
  min-height: 100vh;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  flex-direction: column;

  @media screen and (max-width: 1200px) {
    background-position: center right calc(50vw - 300px - 654px / 2);
  }
}

.mainBlock {
  width: 100%;
  max-width: 588px;
  background: linear-gradient(180deg, #F2F2F7 31.99%, #E5E5F0 100%);

  display: flex;
  flex-direction: column;
  gap: 101px;

  box-shadow: 0 4px 16px 0 #0000001F;
  border-radius: 26px;
  padding: 56px 44px 32px;

  @media screen and (min-width: 1700px) {
    transform: translateX(-304px);
  }
}

.title {
  text-align: center;
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
  color: $black;
}

.subtitle {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: $black;
}

.linkContainer {
  text-align: center;
  font-size: 14px;
  font-weight: 400;

}

.content {
  display: flex;
  gap: 24px;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.rights {

  @media screen and (max-width: 1550px) {
  display: none;
}
  color: white;
  position: absolute;

  height: 52px;
  width: 100%;
  border-top: 1px solid $redPrimary;
  bottom: 0;
}

.rightsText {
  display: block;
  left: 240px;
  padding-left: 240px;
  padding-top: 20px ;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
}