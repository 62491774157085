@import "variables";

@mixin modalWrapper {
  background-color: $white;
  border-radius: 26px;
  z-index: 100;
  padding: 56px 44px;
  box-shadow: 0 4px 16px 0 #0000001F;
}


@mixin boldTextModal {
  font-weight: 700;
  font-size: 18px;
  line-height: 21.6px;
  color: $black;

}

@mixin regularTextModal {
  color: $blackButton;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.8px;
}


@mixin noDataText {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: $greyPrimary;
}

@mixin modalInput {
  width: 100%;
  height: 61px;
  border: 1px solid $lightGrey;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

@mixin modalInputInnerText {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
}

@mixin verticalScrollBar {
  &::-webkit-scrollbar {
    width: 5px;
  };

  &::-webkit-scrollbar-track {
    background: #d3d1ea;
  };

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: $greyPrimary;
  };
}


@mixin scaleDown($screenWidth, $scaleFactor, $originalWidth) {
  @media screen and (max-width: $screenWidth) {
    transform-origin: top right;
    transform: scale(calc(1 - $scaleFactor));
    margin-left: calc(-1 * $scaleFactor * $originalWidth);
  }
}

@mixin scaleDown($screenWidth, $scaleFactor, $originalWidth) {
  @media screen and (max-width: $screenWidth) {
    transform-origin: top right;
    transform: scale(calc(1 - $scaleFactor));
    margin-left: calc(-1 * $scaleFactor * $originalWidth);
  }
}

@mixin libraryButton {
  padding: 10px 28px;
  border-radius: 25px;
  border: 1px solid $blueSec100;
  color: $blueSec100;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  background-color: $white;

  &:hover{
    background-color: #bcc9ee;
    transition: background-color 0.5s ease;

  }
}