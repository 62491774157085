@import "../../../global/variables";
@import "../../../global/mixins";

.wrapper {
  width: 100%;
  max-width: 458px;
  height: 399px;
  @include modalWrapper;

  .invoiceID {
    padding-top: 46px;
    padding-bottom: 10px;
    @include boldTextModal;
  }

  .invoiceAmount {
    padding-top: 10px;
    @include regularTextModal;
  }

  .paidContainer {
    padding-top: 15px;
    display: flex;
    align-items: center;
    gap: 4px;
  }


}

