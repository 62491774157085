@import "../../global/variables";
@import "../../global/mixins";

.error {
  color: $redPrimary;
  height: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 160%;
}

.textarea {
  resize: none;
  outline: none;
  @include verticalScrollBar;
}