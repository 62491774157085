@import "../../global/variables";

.wrapper {
  display: flex;
  align-items: center;

  .pic {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background: linear-gradient(151.27deg, #D11A2B 54.73%, #FFFFFF 128.13%);
    cursor: pointer;

    .picInner {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 22px;
      border: 2px solid $white;
      width: 40px;
      height: 40px;
      color: $white;
      text-transform: uppercase;
    }
  }


  &:hover > .userPopUp {
    display: block;
  }

  .userPopUp {
    padding-top: 50px;
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 20px;
    display: none;

    &:hover {
      display: block;
    }
  }
}
