@import "../../../global/variables";

.wrapper {
  position: relative;
  background: $greyBackground;
  padding: 28px 20px;
  border-radius: 0 16px 16px 16px;
  .title {
    color: $black;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    margin-bottom: 48px;
  }

  .columnsContainer {
    display: flex;

    .column {
      width: 45%;
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      .row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .inputContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-bottom: 40px;

          .info {
            margin-top: 20px;
            color: $greyPrimary;
            font-size: 13px;
            padding-top: 5px;
          }
        }

        .btn {
          border: 2px solid $redPrimary;

          color: $redPrimary;
          text-align: center;
          background: transparent;
          padding: 10px 24px;
          border-radius: 25px;

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 16.8px */
        }
      }

      .checkboxRow {
        display: flex;
        justify-content: space-between;
        align-items: center;

        div:nth-child(2) {
          width: 150px;
        }
      }
    }
  }
}

.label {
  color: $greyPrimary;

  /* H8 */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
  margin-bottom: 8px;
}

.deleteBtn {
  border-radius: 12px;
  background: rgba(209, 26, 43, 0.2);
  border: none;
  padding: 6px;
}

.btnsContainer {
  display: flex;
  align-items: center;
  margin-top: 48px;


  justify-content: flex-end;

  .btnSave {
  }
}

.checkBoxContainer {
  display: flex;
  align-items: center;

  .label {
    color: $greyPrimary;

    margin-bottom: 0px;
    margin-left: 10px;
    cursor: pointer;
  }
}
