@import "../../global/variables";

.topButtonsContainer {
  display: flex;
gap: 20px;
}


.accountsContainer {
  margin-top: 36px;
  width: 100%;
  height: 58px;
  border: 1px solid $greyBackground;
  border-radius: 16px;
  display: flex;
  padding-left: 16px;
  padding-bottom: 2.5px;
  gap: 28px;


  button {
    padding: 15px 10px;
    font-size: 18px;
    line-height: 21.6px;
    color: $greyPrimary;

    &.active {
      color: $black;
      font-weight: 700;
      border-bottom: 3.5px solid $redPrimary;
    }
  }
}

.leftSide {
  display: grid;
  grid-template-columns: 28px 1fr;
  align-items: center;
  gap: 20px;

}

.resetFilters {
  margin: 0 auto 0 20px;
}

