@import "variables.module";

.rating {
  padding-top: 20px;
  padding-bottom: 20px;

  &_top {
    display: flex;
    justify-content: space-between;

  }

  &_title {
    font-size: 18px;
    font-weight: 500;
    color: $darkBlue;
    line-height: 24px;

  }

  .reviewsContainer {
    padding-top: 24px;
    display: flex;
    gap: 20px;
  }

  .leftContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .reviews {
      &_rating {
        color: $darkBlue;
        font-weight: 400;
        font-size: 56px;
        line-height: 64px;

        &_stars {
          position: relative;
          width: fit-content;
          display: flex;

          &_mask {
            position: absolute;
            background-color: white;
            right: 0;
            height: 100%;
          }
        }
      }


      &_number {
        color: $grey;
        font-size: 10px;
        font-weight: 400;
        font-family: Roboto, Arial, sans-serif;
      }
    }
  }



  .rightContainer {
    width: 100%;

    .barContainer {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 16px;

      .number {
        font-family: Roboto, Arial, sans-serif;
        color: $grey;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.3px;
        line-height: 16px;

      }

      .barBackground {
        height: 10px;
        position: relative;
        background-color: #e8eaed;
        max-width: 215px;
        width: 100%;
        border-radius: 5px;



        .bar {
          height: 100%;
          border-radius: 5px;
          position: absolute;
          background-color: $green;
        }
      }
    }
  }


}