@import "header.module";
@import "about.module";
@import "data.module";
@import "rating.module";
@import "comments.module";
@import "whatsNew.module";
@import "aboutModal.module";

@font-face {
  font-family: 'Google Sans';
  src: url('../../../../global/fonts/ProductSans-Light.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Google Sans';
  src: url('../../../../global/fonts/ProductSans-Thin.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Google Sans';
  src: url('../../../../global/fonts/ProductSans-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Google Sans';
  src: url('../../../../global/fonts/ProductSans-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Google Sans';
  src: url('../../../../global/fonts/ProductSans-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Google Sans';
  src: url('../../../../global/fonts/ProductSans-Black.ttf') format('truetype');
  font-weight: 900;
}

.app {
  font-family: Google Sans,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0 auto;
  max-width: 1296px;

  h2 {
    color: $darkBlue;
    font-weight: 500;
    line-height: 24px;
    font-size: 18px;
  }

  p {
    color: $grey;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    margin: 14px 0;
  }
}

.arrow_right {
  color: $grey;
  cursor: pointer;
}

.vert {
  color: $grey;
  cursor: pointer;
}
.md12 { font-size: 12px; }
.md20 { font-size: 20px; }
.md24 { font-size: 24px; }
.md18 { font-size: 18px; }