@import "../../global/variables";

.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  .balanceVal {
    color: $redPrimary;
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
  }

  .walletIcon {
    cursor: pointer;
  }
}
