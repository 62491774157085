@import "../../../global/variables";
@import "../../../global/mixins";


.wrapper {
  width: 100%;
  max-width: 458px;
  @include modalWrapper;

  .warning {
    padding-top: 40px;
    text-align: center;
    @include boldTextModal;

    &Info {
      @include regularTextModal;
      padding-top: 16px;
      text-align: center;
    }
  }

  .icon {
    display: block;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 16px;
  }
}