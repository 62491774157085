@import "../../../global/variables";
@import "../../../global/mixins";

.wrapper {
  width: 100%;
  max-width: 458px;
  @include modalWrapper;

  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;

  }

  .warningInfo {
    text-align: center;
    width: 370px;
    padding-top: 20px;
    @include regularTextModal;
  }

  .info {
    padding-top: 10px;

  }
}
