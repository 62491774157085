@import "../../global/variables";

.filtersBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  width: 100%;
  @media only screen and (max-width: 900px) {
    justify-content: start;
    align-items: start;
  }
}

.emptyDiv {
  height: 47.5px;
  width: 160px;
}

.filterContainer {
  min-width: 200px;
  flex-grow: 1;
}


.rowSelect {
  min-height: 44px;
}

.rightSide {
  display: flex;
  gap: 30px;
  padding-bottom: 30px;
  max-width: 112px;
}

.noGrow {
  flex-grow: 0;
}

.searchContainer {
  &>div {
    min-height: 50px;
  }

}