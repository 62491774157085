@import "../../../global/variables";
@import "../../../global/mixins";

.wrapper {
  width: 100%;
  max-width: 623px;
  @include modalWrapper;

  .contentContainer {
    padding-top: 40px;

    .info {
      width: 100%;
      max-width: 535px;
      @include regularTextModal;
    }
  }

  .noDomain {
    font-size: 18px;
    font-weight: 500;
    padding-top: 30px;
  }

  .linksContainer {
    display: flex;
    flex-direction: column;
    align-items: start;

    .postbacks {
      text-align: left;
      width: 100%;
      border-bottom: 1px solid $lightGrey;
    }

    .label,
    .postbacks {
      color: $greyPrimary;
      font-weight: 400;
      font-size: 14px;
      line-height: 16.8px;
      padding-bottom: 8px;
      padding-top: 24px;
    }

    .input {
      width: 100%;
      height: 61px;
      border: 1px solid $lightGrey;
      border-radius: 8px;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;


      img {
        cursor: pointer;
      }
    }

    .inputInnerText {
      font-weight: 500;
      color: $black;
      white-space: nowrap;
      width: 460px;


      overflow-x: auto;

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }

      &::-webkit-scrollbar-track {
        background: #0000001A;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: $blackButton;
      }
    }
  }
}