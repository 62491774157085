@import "../../global/variables";

.wrapper {
  margin-top: 140px;
  width: fit-content;
  padding: 59px 44px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 26px;
  background: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);

  .titleContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 24px;
    margin-bottom: 40px;
    border-bottom: 1px solid $lightGrey;
    align-items: center;

    h2 {
      color: $black;

      font-size: 24px;
      font-weight: 700;
      line-height: 120%;
      margin-right: 32px;
    }

    img {
      cursor: pointer;
    }
  }

  .text {
    margin-left: auto;
    margin-right: auto;
    color: $greyPrimary;
    text-align: center;
    margin-bottom: 16px;
    max-width: 316px;

    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
  }
}

.hidden {
  display: none;
}

.iconUpload {
  width: 112px;
  height: 112px;
  background: $greyBackground;
  border-radius: 8px;
  border: 1px dashed $greyPrimary;
}

.largeUpload {
  width: 112px;
  height: 180px;
}

.previewContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;

  img {
    border-radius: 8px;
  }
}

.imageSet {
  &:hover {
    border-radius: 8px;
    position: relative;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      lightgray 50%;

    background-size: cover;

    &:after {
      position: absolute;

      content: "";
      background: url("../../img/deleteBtn.svg") no-repeat;
      filter: brightness(0.5) sepia(100) saturate(100) hue-rotate(25deg);
      background-size: contain;
      width: 50px;
      height: 50px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
    }
  }
}

.icon {
  margin-bottom: 6px;
}

.galleryWrapper {
  display: flex;

  .previewContainer {
    width: 112px;
    height: 180px;
    background: $greyBackground;
    border-radius: 8px;
    border: 1px dashed $greyPrimary;
    margin-right: 20px;
  }

  .hoverContainer {
    &:hover {
      position: relative;
      content: "";
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0.4) 100%
        ),
        lightgray 50%;

      background-size: cover;

      z-index: 1;

      &:after {
        position: absolute;

        content: "";
        background: url("../../img/deleteBtn.svg") no-repeat;
        filter: brightness(0.5) sepia(100) saturate(100) hue-rotate(25deg);
        background-size: contain;
        width: 80px;
        height: 80px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        z-index: 1;
      }
    }
  }
}

.uploadContainer {
  display: flex;

  .item {
    margin-right: 20px;
  }
}

.btnsContainer {
  margin-top: 40px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .btn {
    margin-right: 15px;
  }
}
