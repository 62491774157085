@import "../../global/variables";

.datesFilterContainer {
  display: flex;
  gap: 10px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    min-width: 200px;
  }

  &Select {
    min-width: 200px;
  }
}