@import "../../global/variables";

.layout {
  min-width: fit-content;
  position: relative;
  background: linear-gradient(0deg, #ECECF3, #7E879D 102.77%);

  .sidePaddings {
    padding-left: 40px;
    padding-right: 40px;

    @media only screen and (max-width: 900px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .pageContent {
    padding-top: 20px;
    padding-bottom: 74px;
    display: flex;
    gap: 20px;
  }

  .content {
    flex: 1;
    border-radius: 26px;
    border: 1px solid $greyBackground;
    background: #fafafa;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
    padding: 28px 32px;
  }
}
