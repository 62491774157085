@import "../../global/variables";



.wrapper {
  display: flex;
  flex-direction: column;

  .settingsBlock {
    padding: 28px 20px;
    margin-top: 36px;
    display: flex;
    flex-wrap: wrap;

    border-radius: 16px;
    background: $greyBackground;


    .filtersBlock {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      width: 100%;
    }

    .filterContainer {
      min-width: 200px;
      flex-grow: 1;
    }
  }

  .tableSettings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 36px;

    .rightSide {
      display: flex;
      gap: 20px;
    }

    .groupAction {
      width: 100%;
      min-width: 224px;
    }
  }
}

.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
