@import "../../global/variables";

.button {
  padding: 15px 32px;
  text-align: center;
  background: $redPrimary;
  color: $white;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  font-weight: 500;

  img {
    margin-right: 10px;
  }

  border: none;

  &:disabled {
    background: $greyPrimary;
    cursor: no-drop;
    &:hover {
      background: $greyPrimary;
    }
  }

  &:hover {
    background:$red20;
  }
}

.outline {
  background: transparent !important;
  color: $redPrimary;
  font-weight: 500;
}

.outlineWithBorder {
  background: transparent;
  border: 2px solid $redPrimary;
  color: $redPrimary;

  &:hover {
    background: $red20;
    color: $white;
    transition: 0.5s;
  }
  }