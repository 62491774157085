@import "../../global/variables";

.avatarContainer {
  width: 72px;
  height: 72px;
  border-radius: 12px;
  border: 1px dashed $greyPrimary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  input {
    display: none;
  }

  .uploadIcon {
    width: 22px;
    height: 22px;
  }

  .text {
    color: $greyPrimary;
    font-size: 14px;
    line-height: 16.8px;
  }

  .selectedImg {
    position: relative;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 12px;
    }

    &:hover {
      border-radius: 12px;

      &:after {
        position: absolute;
        content: "";
        background: url("../../img/tabletools/download.svg") no-repeat;
        filter: brightness(100) contrast(100);
        background-size: contain;
        width: 22px;
        height: 22px;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
      }
    }
  }
}

