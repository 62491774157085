@import "../../../global/variables";
@import "../../../global/mixins";

.wrapper {
  width: 100%;
  max-width: 458px;
  @include modalWrapper;

 .contentContainer {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }
.icon {
  padding-top: 40px;
}

  @include modalWrapper;

  .warningInfo {
    text-align: center;
    width: 370px;
    padding-top: 16px;
    @include regularTextModal;
  }

}
