@import "variables.module";

.data {
  padding-top: 20px;


  &_top {
    display: flex;
    justify-content: space-between;
  }

  &_frame {
    margin-top: 30px;
    padding: 20px;
    border: 1px solid $lightGrey;
    border-radius: 8px;
    line-height: 20px;

    color: $grey;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.2px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }

  &_block {
    display:flex;
    gap: 20px;
    padding-bottom: 10px;
  }

  &_img {
    width: 20px;
    height: 20px;
  }

  &_moreDetails {
    color: $green;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

}