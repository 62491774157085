@import "../../global/variables";

.tableTools {
  display: flex;
  gap: 12px;

  & > button {
    background: transparent;
    border: none;
    cursor: pointer;
  }

}
.tableTools > *:last-child {
  margin-right: 0;
}
