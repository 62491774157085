@import "variables.module";

.comment {
  padding-top: 16px;

  .comment {
    padding: 16px 0;

    &_top {
      display: flex;
      justify-content: space-between;
    }

    &_title {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    &_avatar {
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }

    &_author {
      font-weight: 400;
      color: $darkBlue;
      font-size: 14px;
      font-family: Roboto, Helvetica, Arial, sans-serif;
      letter-spacing: 0.2px;
      line-height: 20px;

    }

    &_ratingContainer {
      position: relative;
      padding-top: 16px;
      display: flex;
      align-items: center;

      .activeStars {
        z-index: 1;
        clip: rect(10px, 20px, 30px, 40px);
        display: flex;
        align-items: center;
      }

      .inactiveStars {
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
      }
    }

    &_date {
      padding-left: 7px;
      color: $grey;
      font-family: Roboto, Arial, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.3px;
    }

    &_text {
      color: $grey;
      padding-top: 8px;
      font-family: Roboto, Arial, sans-serif;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.2px;
      line-height: 20px;

    }

    &_helpfulText {
      color: $grey;
      padding-top: 16px;
      font-family: Roboto, Arial, sans-serif;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.3px;
      line-height: 16px;

    }

    &_helpfulContainer {
      padding-top: 12px;
      display: flex;
      gap: 24px;
      align-items: center;
    }

    &_question {
      color: $grey;
      font-family: Roboto, Arial, sans-serif;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.3px;
      line-height: 16px;
    }

    &_btnsContainer {
      display: flex;
      gap: 12px;

      .btn {
        border: 1px solid $lightGrey;
        border-radius: 25px;
        background-color: transparent;
        cursor: pointer;
        color: $grey;
        font-size: 14px;
        letter-spacing: 0.25px;
        font-weight: 500;
        line-height: 10px;
        width: 56px;
        padding: 6px 4px;
      }
    }

    &_allReviews {
      padding-top: 20px;
      color: $green;
      font-size: 14px;
      letter-spacing: 0.15px;
      font-weight: 500;
      line-height: 20px;

    }

  }


}