@import "../../../global/variables";
@import "../../../global/mixins";

.wrapper {

  width: 100%;
  max-width: 808px;
  height: 750px;
  border-radius: 26px;
  @include modalWrapper;

  .contentContainer {
    padding-top: 40px;
    display: flex;
    gap: 24px;
    width: 100%;
    flex-direction: column;

  }

  .inputLabel {
    display: block;
    padding-bottom: 8px;
  }

  .negativeComments {
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
    color: $redPrimary;
    padding-left: 20px ;
  }

  .submitBtnContainer {
   align-self: center;
    padding-top: 40px;
  }

  .error {
    height: 0;
    color: $redPrimary;
    font-size: 12px;
    font-weight: 400;
    line-height: 160%;
  }

.loaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_text {
    color: $blackButton;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;

  }

}
}