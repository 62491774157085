@import "../../global/variables";

.sideBar {
  width: 100%;
  max-width: 224px;
  border-radius: 26px;
  border: 1px solid $greyBackground;
  background: $white;
  padding: 24px 12px;
  height: 100%;
  min-height: calc(100vh - 216px);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);

  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: center;

  .item {
    width: 100%;
    display: flex;
    gap: 24px;
    align-items: center;
    height: 48px;
    padding: 8px 32px;
    cursor: pointer;
    text-decoration: none;

    @media only screen and (max-width: 1200px) {
      padding: 8px 8px;
      span {
        display: none;
      }
    }


    span {
      color: $greyPrimary;
      gap: 12px;
    }
  }
}

.active {
  span {
    color: $redPrimary !important;
    font-weight: 700;
    @media only screen and (max-width: 600px) {
      font-weight: 400;
    }
  }

  img {
    filter: invert(24%) sepia(98%) saturate(7210%) hue-rotate(349deg) brightness(86%) contrast(88%);
  }
  border-right: 3px solid $redPrimary;
}
