@import "variables.module";

.about {
  margin-top: 50px;

  &_titleContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }


  &_description {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .updated {
    padding-top: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.25rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;

    &_title {
      color: $darkBlue;
    }

    &_date {
      color: $grey;
      padding-top: 4px;
    }
  }


  .imagesContainer {
    display: flex;
    gap: 10px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .img {
      flex-shrink: 0;
      height: 204px;
      min-width: 92.5px;
      border-radius: 8px;
    }
  }

  .category {
    margin-top: 28px;
    color: $grey;
    border: 1px solid $lightGrey;
    font-weight: 500;
    max-width: 80px;
    vertical-align: middle;
    border-radius: 25px;
    text-align: center;
    padding: 7px 15px;

  }


}