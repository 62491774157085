@import "../../../global/variables";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 28px 20px;
  background: $greyBackground;
  border-radius: 0 16px 16px 16px;


  .contentWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
  }

  .inputContainer {
    padding-top: 24px;

    &:first-child {
      padding-top: 0;
    }

    label {
      margin-bottom: 8px;
      display: block;
    }
  }
}