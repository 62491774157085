@import "../../global/variables";

.searchContainer {
  width: 100%;
  height: 100px;
  background-color: $greyBackground;
  border-radius: 16px;

  display: flex;
  align-items: center;
  padding: 0 20px;
  gap: 40px;
  justify-content: space-between;

  .text {
    font-weight: 400;
    font-size: 18px;
    line-height: 21.6px;
    color: $greyPrimary;
  }
}


.topic {
  cursor: pointer;
}

.topicActive {
  color: $redPrimary;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.2px;


  &::after {
    content: " |";
  }

}


.contentContainer {
  padding-top: 36px;
  display: flex;
  gap: 36px;
  .menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 340px;
    height: 578px;
    border-radius: 26px;
    background-color: $greyBackground;
    align-items: start;
    padding: 32px 0 0 20px;
    gap: 24px;
    color: $greyPrimary;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
  }

  .accordions {
    width: 100%;
    border-radius: 26px;
    background-color: $greyBackground;
    padding: 32px 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}