.sortIcon {
  padding-left: 8px;
  cursor: pointer;

  @media only screen and (max-width: 900px) {
    width: 20px;
    height: 20px;
  }

  &Rotated {
    padding-left: 0;
    padding-right: 8px;
    transform: rotate(180deg);
    @media only screen and (max-width: 900px) {
      padding-right: 5px;
    }
  }
}