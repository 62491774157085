@import "../../../global/variables";
@import "../../../global/mixins";


.wrapper {
  width: 100%;
  max-width: 1056px;
  height: 832px;

  @include modalWrapper;


  .tableModalContainer {
    margin-top: 40px;
    min-height: 540px;
    border-radius: 8px;
    border: 1px solid $lightGrey;

    .tableHeader {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 59px;
      background-color: $greyBackground;
      border-bottom: 1px solid $lightGrey;

      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
    }

    .table {
      display: flex;

      &_leftSide {
        width: 484px;
        height: 481px;
        text-overflow: ellipsis;
        overflow-y: scroll;
        @include verticalScrollBar;

        &_content {
          padding: 16px 32px;
          border-bottom: 1px solid $greyBackground;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;

          &_opened {
            background-color: $greyBackground;
            color: $blackButton;
            white-space: inherit;
            font-weight: 500;
          }

          &:hover {
            background-color: $greyBackground;
            color: $blackButton;
          }
        }

      }

      &_rightSide{
        width: 484px;
        padding: 20px 12px;
        background: linear-gradient(180deg, #F2F2F7 31.99%, #E5E5F0 100%);
        font-size: 12px;
        line-height: 14.4px;
        color: $blackButton;
         .label {
           display: block;
          padding-bottom: 8px;
        }

        &_info {
          font-weight: 700;
          padding-top: 15px;
          padding-bottom: 20px;
        }

        .translationWindow {
          font-family: "Ubuntu", sans-serif;
          outline: none;
          margin-top: 20px;
          height: 246px;
          width: 100%;
          border: 1px solid $lightGrey;
          border-radius: 8px;
          background-color: $white;
          padding: 12px 16px;

          font-size: 15px;
          font-weight: 400;
          line-height: 21px;
          color: $black;
          overflow-y: scroll;

          @include verticalScrollBar;

        }

      }
    }


  }
}