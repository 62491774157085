@import "global/variables";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: $greyBackground;
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

button {
  outline: none;
  border: none;
  background-color: transparent;
}

a {
  color: inherit;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.col-s {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: $black;
  padding-top: 12px;
  padding-bottom: 36px;
}

.filters-panel {
  margin-top: 20px;
  width: 100%;
  min-height: 82px;
  border-radius: 16px;
  background-color: $greyBackground;
  display: flex;
  padding: 16px 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}

.modal {
  &-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    z-index: 2;
  }

  &-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    color: $black;
  }

  &-titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid $lightGrey;
  }

  &-closeIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &-closeBtn {
    color: $redPrimary;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;
    cursor: pointer;
  }

  &-bottomWarapper {
    padding-top: 40px;
    display: flex;
    gap: 50px;
    justify-content: center;
  }
}


@mixin errorMessageMixin {
  display: block;
  font-size: 14px;
  color: $redPrimary;
  height: 0;
  padding-left: 20px;
  transform: translateY(2px);
}

.errorMessage {
  @include errorMessageMixin;
  text-align: center;

  &--left {
    @include errorMessageMixin;
    text-align: left;
  }
}

// autocomplete background style
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill::first-line,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill::first-line,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill::first-line,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
}

