@import "../../global/variables";

.error {
  height: 0;
  color: $redPrimary !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  padding-left: 10px;
}

.placeholder {
  color: $greyPrimary;
  line-height: inherit !important;
  transition: 1s !important;
}