@import "../../global/variables";

.inputLabel{
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  color: $greyPrimary;
  margin-bottom: 8px;
}

.inputContainer {
  display: block;
  position: relative;
}

.customInput {
  outline: none;
  background-color: $white;
  min-height: 56px;
  width: 100%;
  border-radius: 12px;
  border: none;
  font-weight: 400;
  font-size: 18px;
  padding: 17px 16px;
  transition: 0.5s;

  &::placeholder {
    color: $greyDark;
  }

  &.errorInput {
    border: 1px solid $redPrimary;
  }
}

.showBtn {
  height: 18px;
  width: 22px;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 10px);
  right: 18px;

  transition: 0.3s;
}