@import "../../global/variables";

.contentContainer {
  padding-top: 80px;
  padding-left: 120px;

  .title {
    font-weight: 700;
    font-size: 45px;
    line-height: 54px;
    color: $darkBrown;
  }

  .subtitle{
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: $darkBrown;
    width: 100%;
    max-width: 475px;
    padding-top: 40px;

  }

  .link {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: $blueLink;
    text-decoration: underline;
  }
}

.image500,
.image404 {
  position: absolute;
  z-index: -1;
  right: 0;
}


.wrapper {
  position: relative;
  display: flex;
}

