.filtersBlock{
  display: flex;
  justify-content: space-between;
}

.selectContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14.5px;
}

.rowsNumberContainer {
  max-width: 112px;
  padding-bottom: 30px;
}

.usersSelect {
  min-width: 224px;

  &_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}