@import "../../global/variables";

.contentContainer {
  padding-top: 36px;

  .linkContainer {
    display: block;
    padding: 16px 0 18px 20px;
    border: 1px solid $greyDark;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: $greyBackground;

    color: $blackButton;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }

  .innerContainer {
    display: flex;
    gap: 12px;

  }

}