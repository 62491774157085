@import "../../../global/variables";

.wrapper {
  position: relative;
  width: 100%;
  border-radius: 0 16px 16px 16px;
  background: $greyBackground;
  padding: 28px 20px;

  .inputItem{
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 8px;
    }
  }
  .label {
    color: $greyPrimary;
    font-size: 16px;
    font-weight: 500 !important;
    line-height: 120%;
    margin-left: 5px;
  }
}

.content {
  width: 100%;
  max-width: 664px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}


.subdomainInfoBlock {
  padding-top: 24px;

  .infoTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    color: $black;
    padding-bottom: 12px;
  }
}

