@import "../.././global/variables";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 28px 20px;
  background: $greyBackground;
  border-radius: 0 16px 16px 16px;

  justify-content: space-between;

  .container {
    display: flex;
    flex-direction: column;
    gap: 48px;

    & > div > label {
      display: block;
      margin-bottom: 8px;
    }
  }


  .title {
    color: $black;
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;

    margin-bottom: 48px;
  }

  .buttonsContainer {
    padding-top: 48px;
    display: flex;
    justify-content: end;

  }
}