@import "../../global/variables";

.wrapper {
  .title {
    color: $black;
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
  }

  .container {
    margin-top: 36px;
    width: 100%;
  }

  .content {
    width: 100%;
    height: 100%;
  }
}
