@import "../../global/variables";
@import "../../global/mixins";

.billsTable {
  tr {
    grid-template-columns: repeat(4, 1fr);

  }

  .emptyState {
    grid-column: span 4;
    @include noDataText;
  }
}

.transactionsTable {
  tr {
    grid-template-columns: 1fr 1fr 2fr;
  }

  .emptyState {
    grid-column: span 3;
    @include noDataText;
  }
}

.balanceTable {
  tr {
    grid-template-columns: 1fr 1fr;
  }

  .emptyState {
    grid-column: span 2;
    @include noDataText;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.pixelTable {
  tr {
    grid-template-columns: 1fr 1fr 1fr;


    td {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .emptyState {
    grid-column: span 3;
    @include noDataText;
  }
}

.generalStatisticTable {
  tr {
    grid-template-columns: repeat(3, 4fr) repeat(5, 3fr);
  }

  .emptyState {
    grid-column: span 8;
    @include noDataText;
  }
}

.clicksStatisticTable {
  tr {
    grid-template-columns: repeat(4, 5fr) repeat(4, 4fr) repeat(1, 4fr);
  }

  .emptyState {
    grid-column: span 8;
    @include noDataText;
  }
}

.table {
  border-collapse: collapse;
  width: 100%;


  tr {
    display: grid;
  }

  td, th {
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .nameV {
    width: 100%;
    gap: 10px;
    justify-content: start;
    padding-left: 20px;
    text-align: start;
    overflow: hidden;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .tableContent {
    display: block;
    height: 380px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #0000001A;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: $greyPrimary;
    }
  }

  .total {
    font-size: 16px;
    font-weight: 500;
  }

  .iconsContainer {
    display: flex;
    gap: 20px;
  }


  thead {
    tr {
      height: 64px;

      th {
        background: $greyBackground;
        color: $greyPrimary;
        font-size: 14px;
        font-weight: 500;

        @media only screen and (max-width: 1000px) {
          font-size: 10px;
          font-weight: 400;
        }

      }

      th:first-child {
        border-radius: 16px 0 0 0;
      }

      th:last-child {
        border-radius: 0 16px 0 0;
      }
    }
  }

  tbody {
    tr {
      height: 76px;

      td {

        .infoModal {
          text-align: start;
        }
        text-align: center;
        color: $black;
        font-size: 15px;
        border-bottom: 1px solid $greyBackground;

        @media only screen and (max-width: 1000px) {
          font-size: 10px;
        }
      }

      .success {
        color: $blueLink;
        font-weight: 700;
      }

      .inProcess {
        color: $black;
        font-weight: 700;
      }

      .notValid {
        font-weight: 700;
        color: $greyPrimary;
      }

      .negative {
        color: $redPrimary;
      }

      .blue {
        color: $blueLink;
      }

      .infoContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 112px;
        padding: 11.5px;
        background-color: rgba(50, 130, 210, 0.06);
        border-radius: 26px;
        cursor: pointer;

        button {
          color: $blueLink;
          font-size: 14px;
          line-height: 16.8px;
        }
      }
    }
  }
}

