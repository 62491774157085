@import "../../global/variables";
@import "../../global/mixins";


.table {
  border-collapse: collapse;
  width: 100%;

  tr {
    display: grid;
    grid-template-columns: 56px 180px 130px 250px 140px 250px 1fr;

    @media screen and (min-width: 1900px) {
      grid-template-columns: 56px 250px 150px 1fr 160px 500px 1fr;
    }

    @media only screen and (max-width: 1600px) {
      grid-template-columns: 56px 100fr 70fr 180fr 70fr 100px 100fr;
    }
  }

  td, th {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nameV {
    width: 100%;
    gap: 10px;
    justify-content: start;
    padding-left: 20px;
    text-align: start;

    overflow: hidden;

    a {
      color: inherit;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media only screen and (max-width: 900px) {
        font-size: 10px;
        font-weight: 400;
      }
    }
  }

  .countriesListContainer {
    overflow: hidden;
    position: relative;

    .countriesList {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .tableContent {
    display: block;
    height: 380px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: $greyPrimary;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $blackButton;
    }
  }


  thead {
    tr {
      height: 64px;

      th {
        background: $greyBackground;
        color: $greyPrimary;
        font-size: 14px;
        font-weight: 500;
      }

      th:first-child {
        border-radius: 16px 0 0 0;
      }

      th:last-child {
        border-radius: 0 16px 0 0;
      }
    }
  }

  tbody {
    tr {
      height: 76px;

      td {
        text-align: center;
        color: $black;
        font-size: 15px;
        border-bottom: 1px solid $greyBackground;

        @media only screen and (max-width: 900px) {
          font-size: 10px;
          font-weight: 400;
        }
      }

      .statusV {
        font-size: 15px;
        font-weight: 700;
        color: $blackButton;
        overflow: hidden;

        @media only screen and (max-width: 900px) {
          font-size: 10px;
          font-weight: 400;
        }
      }

      .status {
        font-size: 15px;
        font-weight: 700;
        color: #eacc18;
        overflow: hidden;
      }

      .trackerV {
        font-weight: 700;
        color: $greyPrimary;
      }

      .domain {
        color: $blueLink;
        text-decoration: underline;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.emptyState {
  grid-column: span 6;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  color: $greyPrimary !important;
  @include noDataText;
}

.icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}