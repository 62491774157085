@import "../../global/variables";
@import "../../global/mixins";

.container {
  display: flex;
  justify-content: space-between;

}

.answerContainer {
  padding-top: 24px;
}

.rowContainer {
  display: flex;
  gap: 12px;
}

.inputContainer {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.answerTextContainer {
  padding-top: 24px;
}


.wrapper {
  width: 100%;
  margin-top: 32px;

  padding-bottom: 24px;
  border-bottom: 2px solid $greyDark;


  .columnsContainer {
    display: flex;

    .column {
      width: 100%;
      display: flex;
      flex-direction: column;


      &:first-child {
        margin-right: 20px;
      }


      .commentsContainer {
        display: flex;
        flex-direction: column;
        gap: 24px;

      }

      .topRowContainer {
        display: grid;
        grid-template-columns: 72px 1fr 122px 122px;
        gap: 12px;
      }


      .datePicker {
        width: 100%;
      }

    }
  }

  .addAnswer {
    margin-top: 10px;
    color: $redPrimary;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.8px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 13.5px 24px;

  }
}

.label {
  color: $greyPrimary;
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 8px;

}

h4 {
  margin-bottom: 24px;
}

.btnsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  .deleteComment {
    color: $redPrimary;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.8px;
    padding: 10px 20px;
  }
}

.deleteAnswerBtn {
  padding: 13.5px 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: $redPrimary;
  font-size: 14px;
  font-weight: 600;

}
.deleteAnswerIcon {
  width: 24px;
  height: 24px;
  filter: brightness(0.5) sepia(100) saturate(100) hue-rotate(25deg);
}