.points {
  font-size: 14px;
  font-weight: 500;

  p {
    padding-top: 5px;
  }
}

.printScreen {
  padding-bottom: 50px;
  width: 100%;
  height: auto;
}