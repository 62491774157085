@import "../../../global/variables";

.container {
  text-align: center;
  width: 100%;
  padding-right: 40px;



  .title {
    color: $greyPrimary;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.2px;
    border: 1px solid $greyDark;
    border-bottom: none;
    background-color: $greyBackground;
    padding: 20px 0;
    border-radius: 26px 26px 0 0;
  }

  .tableBodyContainer {
    padding-bottom: 25px;
    color: $whitePrimarily;
    border: 1px solid $greyDark;
    border-radius: 0 0 26px 26px;
    box-shadow: 0 4px 16px 0 #0000001F;
  }

  .tableBody {
    display: flex;
    flex-direction: column;
    height: 655px;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: $greyPrimary;
    }

  }

  .ratesRow {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    border-bottom: 1px solid $greyBackground;
    color: $blackButton;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;

    &:hover {
      background-color: $greyBackground;
      font-weight: 700;
      font-size: 15px;
      line-height: 21px;
      color: $blackButton;
    }
  }
}

