@import "../../global/variables";

.header {
  position: relative;

  display: flex;
  justify-content: space-between;
  height: 68px;
  background-color: $whitePrimarily;
  border-radius: 0 0 26px 26px;
  margin-left: 40px;
  margin-right: 40px;
  box-shadow: 0 4px 16px 0 #0000001F;

  @media only screen and (max-width: 900px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .logo {
    cursor: pointer;
  }

  .rightSideContainer {
    display: flex;
    align-items: center;
    gap: 80px;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}