@import "../../../global/variables";
@import "../../../global/mixins";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 28px 20px;
  background: $greyBackground;
  border-radius: 0 16px 16px 16px;
  justify-content: space-between;


  .sidesWrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 30px;

    @media screen and (max-width: 1600px) {
      display: flex;
      justify-content: space-between;
    }

    .leftSide {
      min-width: 0;
      width: fit-content;
      .titleContainer {
        .title {
          margin-bottom: 12px;
        }

        .subTitle {
          color: $black;
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
        }
        margin-bottom: 48px;
      }

      .inputsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;

        .inputItem {
          flex-direction: column;
          width: 100%;
          margin-bottom: 24px;

          &_vertical {
            display: none;
          }

          label {
            color: $greyPrimary;

            font-size: 14px;
            font-weight: 700;
            line-height: 120%;
            margin-bottom: 8px;
          }
        }
      }
    }

    .preview {
      @include scaleDown(1500px, 0.2, 375px);
      @include scaleDown(1400px, 0.4, 375px);
      @media screen and (max-width: 1340px) {
        display: none;
      }
    }
  }
}

.downloadFilesContainer {
  margin-bottom: 48px;
}

.deleteIcon {
  border: none;
  background-color: transparent;


  img {
    filter: brightness(0.5) sepia(100) saturate(100) hue-rotate(25deg);
  }
}

.uploadBtn {
  border: 1px dashed $redPrimary;
  padding: 10px 24px;
  background: transparent;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  border-radius: 24px;
  color: $redPrimary;

  &:disabled {
    cursor: no-drop;
    color: $greyDark;
    border: 1px dashed $greyDark;

    img {
      filter: saturate(0) brightness(2);
    }
  }
}

.templatesContainer {

  &_title {
    width: 100%;
    height: 46px;
    background-color: $greyDark;
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 12px;

    color: $blackButton;
    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
  }


  .imagesContainer {
    position: relative;
    width: 100%;
    overflow-x: auto;
    max-width: fit-content;
    padding: 14px 16px;
    background: $white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid $greyPrimary;
    }

    &Selected {
      background-color: $greyDark;
    }

    .iconContainer {
      position: relative;
      border-radius: 8px;
      background: $lightGrey;
      width: 92px;
      height: 92px;
      background-image: url("../../../img/gallery.svg");
      background-position: center;
      background-repeat: no-repeat;
    }

    .imgContainer {
      position: relative;
      border-radius: 8px;
      height: 180px;
      width: 112px;
      background: $lightGrey;
      background-image: url("../../../img/gallery.svg");
      background-position: center;
      background-repeat: no-repeat;

      &:hover {
        background-image: none;
        & > .uploadImgContainer {
          opacity: 1;
        }
      }
    }
  }

  .uploadImgContainer {
    opacity: 0;
    transition: 0.5s;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    color: $white;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    font-size: 12px;
    font-weight: 600;
  }
}

.imgPreview {
  border-radius: 8px;
  height: 180px;
  width: 112px;
  object-fit: cover;
}

.iconPreview {
  border-radius: 8px;
  height: 92px;
  width: 92px
}

.btnsContainer {
  display: flex;
  margin-top: 48px;
  width: 100%;
  justify-content: flex-end;

  .btn {
    margin-left: 45px;
  }
}
.sounds {
  margin-bottom: 40px;
}

.hidden {
  display: none;
}