@import "../../global/variables";

.search {
  position: relative;
  width: 100%;
  max-width: 300px;
  background: white;
  border: 1px solid $lightGrey;
  border-radius: 8px;
  display: flex;
  align-content: center;

  .closeIcon {
    position: absolute;
    right: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    align-self: center;
  }

  input {
    background: none;
    color: $greyPrimary;
    width: 268px;
    min-height: 44px;
    margin-left: 4px;
    padding: 10px 35px;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    outline: none;
    border: none;

  }

  &::after {
    content: url("../../img/searchIcon.svg");
    width: 24px;
    height: 24px;
    position: absolute;
    left: 10px;
    top: 10px;
  }
}
