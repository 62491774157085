@import "variables.module";

.whatsNew {
  padding-top: 20px;



 .developers {
   display: flex;
   justify-content: space-between;

 }
  .icon {
    color: $grey;
    cursor: pointer;
    user-select: none;
  }

  .developerContactContainer {
    display: flex;
    gap: 16px;
  }

  .emailIcon {
    color: $grey;
  }

  .email {
    font-size: 14px;
    letter-spacing: 0.25px;
    font-weight: 500;
    color: $darkBlue;
    padding-bottom: 5px;
  }

  .address {
    color: $grey;
  }

  .footerLine {
    margin-top: 36px;
    margin-bottom: 36px;
    width: 100%;
    height: 0.5px;
    background-color: $lightGrey;
  }

}