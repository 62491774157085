@import "variables.module";

.aboutModal {
  background-color: #20212499;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;


  .container {
    background-color: white;
    box-sizing: border-box;
    display: flex;
    z-index: 11;
    flex-direction: column;
    height: calc(100vh - 32px);
    max-height: 720px;
    max-width: 720px;
    width: calc(100vw - 32px);
    margin: 16px auto;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 #3c40434d, 0 4px 8px 3px #3c404326;

    .header {
      padding: 24px 32px;
      display: flex;
      justify-content: space-between;

      &_leftSide{
        display: flex;
        gap: 16px;
      }

      &_icon {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        box-shadow: 0 -1px 5px #00000017, 0 3px 5px #0000000f, 0 1px 2px #0000004d, 0 1px 3px #00000026;
      }

      .title {
        line-height: 28px;
        font-weight: 500;
        font-size: 22px;
        color: $darkBlue;
        padding-bottom: 6px;
        font-family: Roboto, Arial, sans-serif;
      }

      .subtitle {
        font-size: 14px;
        color: $grey;
        font-weight: 400;

      }

      .closeButton {
        cursor: pointer;
        color: $grey;
      }
    }

    .content {
      padding: 0 32px 28px 32px;
      overflow-y: auto;
    }
  }

}