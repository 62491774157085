$black: #2E2B2B;
$blackButton: #4D4D5E;
$greyPrimary: #939CB4;
$greyBackground: #ECECF3;
$redPrimary: #D11A2B;
$white: #FBFBFB;
$redGradient: #D11A2B, #FFFFFF;
$lightGrey: #D8DCE0;
$greyDark: #CBCBE0;
$red20: #A71522;
$selectedBlue: #1976d2;
$yellow: #FFCB3D;
$blueLink: #3282D2;
$blueSec100: #7B91DF;
$whitePrimarily: #FAFAFA;
$darkBrown: #403030;
$green: #01875f;
$greyRegular: #636b6f;