@import "../../../global/variables";
@import "../../../global/mixins";


.wrapper {
  width: 100%;
  max-width: 458px;
  height: 369px;
  @include modalWrapper;

  .warning {
    padding-top: 40px;
    padding-bottom: 16px;
    @include boldTextModal;
    text-align: center;
  }

  .info {
    @include regularTextModal;
    text-align: center;
  }
}