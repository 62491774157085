@import "../../global/variables";

button {
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  width: 182px;
  border-radius: 8px;
  border: 1px solid $white;
  background: $white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);

  .name {
    display: flex;
    margin-bottom: 20px;
    overflow-x: hidden;
    align-items: center;

    img {
      margin-right: 8px;
    }

    .username {
      color: $black;
      font-size: 12px;
      font-weight: 400;
      line-height: 120%;
    }
  }

  .section {
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    gap: 16px;
    font-weight: 700;

    &:last-child {
      margin-bottom: 16px;
    }
    button {
      color: $white;
      background: transparent;
      border: none;

      font-size: 14px;
      font-weight: 400;
      line-height: 120%;

      &:hover {
        font-weight: 600;
      }
    }

    .support {
      color: $blackButton;
      font-size: 14px;
      font-weight: 700;
      line-height: 120%;

      &:hover {
        color: $black;
      }
    }
  }

  .logout {
    display: flex;
    background: transparent;
    border: none;
    align-items: center;
    font-weight: 700;

    &:hover {
      p {
        color: $red20;
      }
    }

    img {
        margin-right: 16px;
    }

    p {
      color: $redPrimary;
      font-size: 14px;
      font-weight: 700;
      line-height: 120%;
    }
  }
}
