@import "../../../global/variables";
@import "../../../global/mixins";


.wrapper {
  width: 100%;
  max-width: 623px;
  height: 642px;
  @include modalWrapper;

  .paymentContainer {
    padding-top: 40px;


    .currencyTitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 16.8px;
      color: $greyPrimary;
      padding-bottom: 8px;

    }

    .currency {
      @include modalInput;

      img {
        cursor: pointer;
      }


      span {
        @include modalInputInnerText;
        color: $greyPrimary
      }
    }
  }

  .text {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    color: $greyPrimary;
    padding-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    max-width: 427px;
    text-align: center;
  }

  .support {
    color: $blueLink;
    cursor: pointer;

  }

  .bottomWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .warning {
      border-radius: 8px;
      padding: 12px;
      text-align: center;
      background-color: rgba(255, 211, 98, 0.85);
      font-weight: 400;
      font-size: 14px;
      line-height: 16.8px;
      color: $blackButton;
      width: 100%;
      .bold{
        font-weight: 700;

      }
    }
  }

}

