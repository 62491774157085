@import "../../../global/variables";
@import "../../../global/mixins";

label {
  color: $greyPrimary;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 8px;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 28px 20px;
  background: $greyBackground;
  border-radius: 0 16px 16px 16px;
  gap: 100px;

  @media screen and (max-width: 1400px) {
    gap: 20px;
  }

  .column {
    display: flex;
    flex-direction: column;

    &:last-child {
      @include scaleDown(1400px, 0.2, 375px);
      @include scaleDown(1020px, 0.4, 375px);
    }

    h2 {
      color: $black;
      font-size: 24px;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 12px;
    }

    .subTitle {
      margin-bottom: 32px;
      color: $black;
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
    }

    .accordionsBlock {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: 32px;

      .inputRow {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: end;

        &:not(:last-of-type) {
          margin-bottom: 32px;
        }

        .inputItem {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: end;

          &_hide {
            display: none;
          }
        }
      }
    }
  }
}

.slidersColumn {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  width: 100%;

  .sliderItem {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    p {
      color: $black;
      margin-right: 13px;
      font-size: 18px;
      font-weight: 400;
      line-height: 120%;
    }
  }
}

.inputRowTextArea {
  margin-bottom: 32px;

  .inputItem {
    display: flex;
    flex-direction: column;

  }
}

.libraryWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 34.5px;
  padding-bottom: 22.5px;

  @media screen and (max-width: 1510px) {
    flex-direction: column;
    gap: 20px;

  }


  .libraryDescription {
    display: flex;
    gap: 16px;


    &_title {
      font-size: 12px;
      font-weight: 700;
      line-height: 14.4px;
      color: $greyPrimary;
      padding-bottom: 4px;
    }

    &_subtitle {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
      color: $greyPrimary;
      min-width: 307px;

    }

  }

  .libraryBtn {
    @include libraryButton;
  }
}




