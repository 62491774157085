@import "../../../global/variables";
@import "../../../global/mixins";

.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
  padding: 28px 20px;
  background: $greyBackground;
  border-radius: 0 16px 16px 16px;

  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr;
    .preview {
      display: none;
    }
  }

  .column {
    .titleContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;

      h2 {
        color: $black;
        font-size: 24px;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 12px;
      }

      p {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
      }
    }

    .localizationContainer {
        margin-bottom: 32px;
    }

    .addBtnContainer {
      margin-top: 45px;
      margin-bottom: 24px;
    }
  }
}

.addCommentBtn {
  padding: 13.5px 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: $redPrimary;
  font-size: 14px;
  font-weight: 600;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
}

.generateCommentBtn {
  @include libraryButton;
}

.title {
  margin-bottom: 24px;
  color: $blackButton;
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
}