@import "variables.module";

.header {
  .title {
    display: flex;
    gap: 24px;

    .icon {
      display: block;
      width: 72px;
      height: 72px;
      margin-top: 7px;
      border-radius: 20%;
      box-shadow: 0 1px 2px 0 #3c40434d, 0 1px 3px 1px #3c404326;
    }

    &_name {
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2rem;
      color: $darkBlue;
    }

    &_author {
      color: $green;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: .00625em;
      line-height: 1.5rem;
    }

    &_ads {
      font-family: Roboto, Arial, sans-serif;
      color: $grey;
      font-size: .75rem;
      font-weight: 400;
      letter-spacing: .025em;
      line-height: 1rem;
    }
  }

  .info {
    margin: 16px -24px 24px;
    padding: 12px 24px;
    display: flex;
    width: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }


    &_item {
      flex-shrink: 0;
      box-sizing: border-box;
      padding: 0 16px;
      min-width: 96px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 44px;

      &:first-of-type {
        padding-left: 0;
      }

      &:not(:first-of-type):before {
        background-color: #e8eaed;
        content: "";
        display: block;
        height: 24px;
        left: 0;
        position: absolute;
        top: calc(50% - 12px);
        width: 1px;
      }

      .topLine {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $darkBlue;
        font-size: 14px;
        font-weight: 500;
        height: 24px;
        letter-spacing: .0178571429em;
        line-height: 1.25rem;
        white-space: nowrap;
      }

      .bottomLine {
        font-family: Roboto, Arial, sans-serif;
        font-size: .75rem;
        font-weight: 400;
        height: 20px;
        color: $grey;
        letter-spacing: .025em;
        line-height: 1rem;
        white-space: nowrap;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

      }
    }
  }

  .installBtn {
    background-color: $green;
    width: 100%;
    height: 36px;
    border-radius: 8px;
    border: none;
    color: $white;
    font-size: 14px;
    text-align: center;
    padding: 8px 0 8px 0;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 6px;
  }

  .progressBar {
    &Container {
      width: 100%;
      height: 36px;
      margin-bottom: 6px;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &Text {
      color: $grey;
    }

    &Background {
      position: relative;
      width: 100%;
      height: 5px;
      border-radius: 2.5px;
      background-color: $lightGreen;
    }

    &Fill {
      position: absolute;
      height: 100%;
      border-radius: 2.5px;
      width: 1%;
      background: $green;
    }
  }

  .wishlist {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    gap: 12px;
    color: $green;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.15px;
  }

  .extraInfo {
    display: flex;
    align-items: center;
    gap: 12px;
    color: $grey;
    font-weight: 400;
    font-size: 14px;
    margin-top: 16px;
  }
}