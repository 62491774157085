@import "../../../global/variables";
@import "../../../global/mixins";

.wrapper {
  width: 100%;
  max-width: 458px;
  height: 386px;
  @include modalWrapper;

  .reportInfo {
    text-align: center;
    padding-top: 40px;
    @include boldTextModal;
  }

  .startInfo {
    text-align: center;
    padding-top: 16px;
    @include regularTextModal;
  }

}